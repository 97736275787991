<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="成为消费合伙人" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div style="margin-top:46px;height: 100%;background: #fff">
      <img class="imgs" src="../../assets/img/hehuo1.png" alt />

      <div class="bott">
        <van-button
          class="bot"
          color="linear-gradient(180deg, #F31A00 0%, #A80A00 77%)"
          @click="zhifu"
          v-preventReClick
        >
          <span style="color: #fff;" class="tes">立即加入</span>
        </van-button>
      </div>
      <img class="imgs" src="../../assets/img/hehuo2.png" alt />
    </div>
    <van-popup class="ctisi" v-model="show" closeable>
      <h2 style="font-size: 20px;">尊敬的客户：</h2>
      <div style="text-indent:2em;line-height: 26px; margin-top: 5px;">
        欢迎您加入我们，成为消费合伙人，在此之前请确认您所在的当前门店，
        <span style="color: red;font-size: 19px;">{{shangjia.name}}。</span>如果正确请点击"继续成为消费合伙人"，否则请点击"更换门店"
      </div>
      <van-button class="bost" type="primary" @click="tosig">继续成为消费合伙人</van-button>
      <van-button class="bost" type="danger" @click="tomengdian">更换门店</van-button>
    </van-popup>
  </div>
</template>

<script>
import orderModel from "../../api/order";
import userModel from "../../api/user";
import utils from "../../assets/utils";
import preventReClick from "../../assets/preventReClick";
export default {
  name: "esignTest",
  components: {},
  data() {
    return {
      list: [],
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      active: "0",
      isgoumai: false,
      show: false,
      isWxMini: ""
      //--------------
    };
  },
  methods: {
    // ---------------
    qiehuan(row) {
      this.active = row;
      this.onLoad();
    },
    zhifu() {
      var _this =this
      if (this.isgoumai == true) {
        this.$toast("您已经是消费合伙人，不能重复购买");
        return;
      }
      if (
        this.chexin == null ||
        this.chexin.carNo == "" ||
        this.chexin.carNo == null
      ) {
        _this.$toast("请先认证车牌再购买！");
        setTimeout(() => {
          window.location.href = "/authentication";
        }, 1000);

        return;
      }
      this.show = true;
    },
    tosig() {
      this.$router.push({
        path: "signature"
      });
      this.show = false;
    },
    tomengdian() {
      this.$router.push({
        path: "Store_list",
        query: {
          isok: false,
          title:'点击更换门店'
        }
      });
      this.show = false;
    },
    // zhifu() {
    //   this.showDom = true;
    //   this.clickBut();
    //   return;
    //   var _this = this;
    //   if (
    //     this.chexin == null ||
    //     this.chexin.carNo == "" ||
    //     this.chexin.carNo == null
    //   ) {
    //     _this.$toast("请先认证车牌再购买！");
    //     setTimeout(() => {
    //       window.location.href = "/authentication";
    //     }, 1000);

    //     return;
    //   }
    //   var lis = {
    //     id: 43,
    //     // activityPrice: 1980,
    //     activityPrice: 0.01,
    //     customerId: this.userInfo ? this.userInfo.customerId : "",
    //     gid: this.shangjia.id,
    //     payType: 1,
    //     type: 12,
    //     // shareId: this.shareId ? this.shareId : null,
    //     name: "1980元合伙人套餐",
    //     areaDetail:
    //       this.shangjia.province +
    //       this.shangjia.city +
    //       this.shangjia.district +
    //       this.shangjia.address, //维修厂详细地址
    //     carModel: this.chexin ? this.chexin.carBrand : "", //车型
    //     carNo: this.chexin ? this.chexin.carNo : "", //车牌
    //     garageName: this.shangjia.name, //修理厂名称
    //     positionJd: this.shangjia.positionJd,
    //     positionWd: this.shangjia.positionWd,
    //     tel: this.shangjia.legalPersonPhone,
    //     receiver: this.shangjia.legalPerson //用户名称
    //   };
    //   var isWxMini = window.__wxjs_environment === "miniprogram";
    //   console.log(isWxMini);
    //   if (isWxMini) {
    //     lis.token = sessionStorage.getItem("Authorization");
    //     lis.ccisok = 4; //3:会员卡订单 4:套餐订单 5:充值卡订单
    //     let payDataStr = JSON.stringify(lis);
    //     wx.miniProgram.navigateTo({
    //       url: "/pages/pay/pay?payDataStr=" + payDataStr // 微信小程序调用支付的页面
    //     });
    //   } else {
    //     orderModel.goumaivip(lis).then(res => {
    //       if (res.code == 200) {
    //         if (typeof WeixinJSBridge === "undefined") {
    //           // 微信浏览器内置对象。参考微信官方文档
    //           if (document.addEventListener) {
    //             document.addEventListener(
    //               "WeixinJSBridgeReady",
    //               _this.onBridgeReady(res.data),
    //               false
    //             );
    //           } else if (document.attachEvent) {
    //             document.attachEvent(
    //               "WeixinJSBridgeReady",
    //               _this.onBridgeReady(res.data)
    //             );
    //             document.attachEvent(
    //               "onWeixinJSBridgeReady",
    //               _this.onBridgeReady(res.data)
    //             );
    //           }
    //         } else {
    //           console.log("准备调用微信支付");
    //           _this.onBridgeReady(res.data);
    //         }
    //       }
    //     });
    //   }
    // },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign // 微信签名
        },
        function(res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast.success("支付成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                data.id +
                "&status=" +
                3 +
                "&type=" +
                10 +
                "&rowtype=" +
                0 +
                "&isok=" +
                true;
              // vm.$router.go(-1);
            }, 1000);
          } else {
            vm.$toast("支付失败");
          }
        }
      );
    },
    onLoad() {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中..."
      });
      userModel
        .partner()
        .then(e => {
          loading.clear();
          this.isgoumai = e.data;
        })
        .catch(() => loading.clear());
    },

    back() {
      this.$router.go(-1);
    }
  },
  created() {
    this.height = window.innerHeight;
    console.log(this.height);
    this.onLoad();
    // console.log(utils.getUrlKey("status"));
  }
};
</script>

<style lang="less" scoped>
.order_list {
  margin: 0 10px;
  background: #fff;
  padding: 0 10px;
  border-radius: 5px;
  overflow: hidden;
}
.ordrt_h1 {
  overflow: hidden;
  color: #666;
  font-size: 12px;
  padding-top: 10px;
}
.zhifu {
  // display: inline-block;
  font-size: 12px;
  padding: 0 6px;
  color: #b22222;
  border: 1px solid #b22222;
  border-radius: 12px;
  float: right;
  line-height: 20px;
  height: 22px;
  font-weight: bold;
}
.hed_lis {
  height: 45px;
  background: #fff;
  border-bottom: 1px solid #ebedf0;
  position: fixed;
  top: 46px;
  width: 100%;
  z-index: 99;
}
.hhcc {
  float: left;
  font-size: 14px;
  color: #646566;
  width: 50%;
  text-align: center;
  line-height: 45px;
  position: relative;
  overflow: hidden;
}
.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  background-color: #ee0a24;
  border-radius: 1.5px;
}
.bot {
  width: 100%;
  overflow: hidden;
  padding-bottom: 10px;
}
.imgs {
  width: 100%;
  display: block;
}
.bott {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 100%;
  height: 50px;
  background: #f5f5f5;
  // z-index: 99;
  text-align: center;
}
.bot {
  box-shadow: 0 2px 3px 1px rgba(212, 0, 0, 0.47);
  border-radius: 20px;
  height: 40px;
  width: 89%;
  margin: auto;
  margin-top: 5px;
}
.tes {
  display: inline-block;
  line-height: 40px;
  // margin-top: 8px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  letter-spacing: 0;
}
// ------------------------------
.qianming {
  height: 100%;
  width: 100%;
  z-index: 99;
}
// 弹窗
.ctisi {
  width: 68%;
  font-size: 15px;
  color: #000;
  padding: 20px 25px;
  border: 2px solid #dcdcdc;
}
.bost {
  height: 30px;
  border-radius: 8px;
  width: 90%;
  margin: auto;
  display: block;
  margin-top: 15px;
  font-size: 15px;
}
</style>
<style lang="less">
.van-tabs__line {
  bottom: 20px;
}
.van-overlay {
  // background-color: rgba(0, 0, 0, 0.6);
}
</style>
